import { defineStore } from "pinia";

export type SortBy = "serial" | "software_version" | "pinged";

interface DeviceState {
  sortBy: SortBy;
}

export const useDeviceStore = defineStore("devices", {
  state: (): DeviceState => ({
    sortBy: "serial",
  }),
  actions: {
    updateSort(payload: SortBy) {
      this.sortBy = payload;
    },
  },
});
