import AboutPage from "@/pages/AboutPage.vue";
import ContactOiviPage from "@/pages/Contact/ContactOiviPage.vue";
import SignOutPage from "@/pages/SignOut/SignOutPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import AddPatientPage from "../pages/AddPatient/AddPatientPage.vue";
import AnnotationPage from "../pages/Annotation/AnnotationPage.vue";
import ChannelDetailsPage from "../pages/Channels/ChannelDetailsPage.vue";
import ChannelsPage from "../pages/Channels/ChannelsPage.vue";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage.vue";
import ImageListPage from "../pages/Images/ImageListPage.vue";
import CrashLogsListPage from "../pages/Logs/CrashLogsListPage.vue";
import CrashLogDetailPage from "../pages/Logs/CrashLogDetailPage.vue";
import NotFound from "../pages/NotFound.vue";
import NotPermitted from "../pages/NotPermitted.vue";
import LocationDetailsPage from "../pages/Organizations/LocationDetailsPage.vue";
import OrgDetailsPage from "../pages/Organizations/OrgDetailsPage.vue";
import OrgListPage from "../pages/Organizations/OrgListPage.vue";
import PackageDetailsPage from "../pages/Packages/PackageDetailsPage.vue";
import PackagesPage from "../pages/Packages/PackagesPage.vue";
import PatientPage from "../pages/Patient/PatientPage.vue";
import PatientImportsPage from "../pages/Patients/PatientImportsPage.vue";
import PatientsPage from "../pages/Patients/PatientsPage.vue";
import SettingsPage from "../pages/Settings/SettingsPage.vue";
import SignInPage from "../pages/SignIn/SignInPage.vue";
import SignUpPage from "../pages/SignUp/SignUpPage.vue";
import SignUpConfirmPage from "../pages/SignUpConfirm/SignUpConfirmPage.vue";
import StartPage from "../pages/StartPage.vue";
import StatisticsOverviewPage from "../pages/Statistics/StatisticsOverviewPage.vue";
import StatisticsPage from "../pages/Statistics/StatisticsPage.vue";
import MyAccountPage from "../pages/Users/MyAccountPage.vue";
import UserDetailsPage from "../pages/Users/UserDetailsPage.vue";
import UserListPage from "../pages/Users/UserListPage.vue";
import VisitDetailsPage from "../pages/Visits/VisitDetailsPage.vue";
import VisitReportPage from "../pages/Visits/VisitReportPage.vue";
import VisitsLookup from "../pages/Visits/VisitsLookup.vue";
import VisitsPage from "../pages/Visits/VisitsPage.vue";

import DeviceDetailsPage from "../pages/Devices/DeviceDetailsPage.vue";
import DevicesPage from "../pages/Devices/DevicesPage.vue";

import AuditLogsListPage from "@/pages/AuditLogs/AuditLogsListPage.vue";
import { AppConfig } from "@/util/AppConfig";

let routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignInPage,
    meta: { public: true },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUpPage,
    meta: { public: true },
  },
  {
    path: "/sign-up/:code",
    name: "SignUpWithCode",
    component: SignUpPage,
    props: true,
    meta: { public: true },
  },
  {
    path: "/sign-up-confirm",
    name: "SignUpConfirm",
    component: SignUpConfirmPage,
    meta: { public: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordPage,
    meta: { public: true },
  },
  {
    path: "/",
    name: "Home",
    component: StartPage,
  },
  {
    path: "/patients",
    name: "Patients",
    component: PatientsPage,
    meta: {
      title: "Patients",
      privilege: "VIEW_PATIENTS",
      inNavBar: true,
      navBarGroup: "Patients",
    },
  },
  {
    path: "/patients/:patientID",
    name: "Patient",
    component: PatientPage,
    props: true,
    meta: {
      title: "Patient Details",
      navBarGroup: "Patients",
      privilege: "VIEW_PATIENTS",
    },
  },
  {
    path: "/patients/add",
    component: AddPatientPage,
    meta: {
      title: "Add Patient",
      navBarGroup: "Patients",
      privilege: "VIEW_PATIENTS",
    },
  },
  {
    path: "/visits",
    name: "Visits",
    component: VisitsPage,
    meta: {
      title: "Visits",
      navBarGroup: "Visits",
      inNavBar: true,
      privilege: "VIEW_VISITS",
    },
  },
  {
    path: "/visits/lookup",
    name: "VisitsLookup",
    component: VisitsLookup,
    meta: {
      title: "Visits Lookup",
      privilege: "VIEW_VISITS",
      hideNavbar: true,
    },
  },
  {
    path: "/visits/:visitID",
    component: VisitDetailsPage,
    props: true,
    meta: {
      title: "Visit Details",
      navBarGroup: "Visits",
      privilege: "VIEW_VISITS",
    },
  },
  {
    path: "/visits/:visitID/analysis",
    name: "Annotation",
    component: AnnotationPage,
    props: true,
    meta: {
      title: "Visit Analysis",
      navBarGroup: "Visits",
      privilege: "VIEW_VISITS",
    },
  },
  {
    path: "/visits/:visitID/report",
    name: "View report",
    component: VisitReportPage,
    props: true,
    meta: {
      title: "Visit Report",
      navBarGroup: "Visits",
      privilege: "VIEW_VISITS",
    },
  },
  {
    path: "/users/:userID",
    component: UserDetailsPage,
    name: "User",
    props: true,
    meta: {
      title: "User Details",
      navBarGroup: "Users",
      privilege: "VIEW_USERS",
    },
  },
  {
    path: "/users",
    component: UserListPage,
    name: "Users",
    meta: {
      title: "Users",
      privilege: "VIEW_USERS",
      inNavBar: true,
      navBarGroup: "Users",
    },
  },
  {
    path: "/organizations",
    name: "Organizations",
    component: OrgListPage,
    meta: {
      title: "Organizations",
      privilege: ["ACCESS_ALL_ORGANIZATIONS", "VIEW_ORGANIZATION"],
      inNavBar: true,
      navBarGroup: "Organizations",
    },
  },
  {
    path: "/organizations/:orgID",
    component: OrgDetailsPage,
    props: true,
    meta: {
      title: "Organization Details",
      navBarGroup: "Organizations",
      privilege: ["VIEW_ORGANIZATION"],
    },
  },
  {
    path: "/locations/:locationID",
    component: LocationDetailsPage,
    props: true,
    meta: {
      title: "Clinic Details",
      navBarGroup: "Locations",
      privilege: ["VIEW_ORGANIZATION"],
    },
  },
  {
    path: "/:notFound(.*)",
    component: NotFound,
  },
  {
    path: "/:notPermitted(.*)",
    name: "NotPermitted",
    component: NotPermitted,
  },
  {
    path: "/images",
    name: "Images",
    component: ImageListPage,
    meta: {
      title: "Images",
      privilege: "VIEW_STATISTICS",
      inNavBar: true,
      navBarGroup: "Images",
    },
  },
  {
    path: "/devices",
    name: "Devices",
    component: DevicesPage,
    meta: {
      title: "Devices",
      privilege: "VIEW_DEVICES",
      inNavBar: true,
      navBarGroup: "Devices",
    },
  },
  {
    path: "/statistics",
    name: "Detailed",
    component: StatisticsPage,
    meta: {
      title: "Statistics Detailed",
      privilege: "VIEW_STATISTICS",
      isNavDropDown: true,
      navBarGroup: "Statistics",
    },
  },
  {
    path: "/statistics/overview",
    name: "Overview",
    component: StatisticsOverviewPage,
    meta: {
      title: "Statistics Overview",
      privilege: "VIEW_STATISTICS",
      isNavDropDown: true,
      navBarGroup: "Statistics",
    },
  },
  {
    path: "/device/:deviceID",
    component: DeviceDetailsPage,
    name: "Device details",
    props: true,
    meta: {
      title: "Device details",
      navBarGroup: "Devices",
      privilege: "VIEW_DEVICES",
    },
  },
  {
    path: "/packages/:packageID",
    name: "Software packages - details",
    component: PackageDetailsPage,
    props: true,
    meta: {
      title: "Software channels",
      navBarGroup: "Admin",
      privilege: "CONFIGURE_SYSTEM",
    },
  },
  {
    path: "/channels/:channelID",
    component: ChannelDetailsPage,
    props: true,
    meta: {
      title: "Software channel Details",
      navBarGroup: "Admin",
      privilege: "CONFIGURE_SYSTEM",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactOiviPage,
    props: true,
    meta: {
      title: "Contact Oivi",
      inNavBar: true,
      navBarGroup: "Contact",
    },
  },
  {
    path: "/logs/crash",
    name: "Logs",
    component: CrashLogsListPage,
    meta: {
      title: "Crash Logs",
      privilege: "VIEW_LOGS",
      isNavDropDown: true,
      navBarGroup: "Admin",
    },
  },
  {
    path: "/logs/crash/:logID",
    name: "Log Details",
    component: CrashLogDetailPage,
    props: true,
    meta: {
      title: "Crash log detail",
      privilege: "VIEW_LOGS",
      navBarGroup: "Admin",
    },
  },
  {
    path: "/audit/logs",
    name: "Audit Logs",
    component: AuditLogsListPage,
    meta: {
      title: "Audit Logs",
      privilege: "VIEW_LOGS",
      isNavDropDown: true,
      navBarGroup: "Admin",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
    meta: {
      title: "Settings",
      privilege: ["CONFIGURE_SYSTEM", "VIEW_LOGS"],
      isNavDropDown: true,
      navBarGroup: "Admin",
    },
  },
  {
    path: "/channels",
    name: "Software channels",
    component: ChannelsPage,
    meta: {
      title: "Software channels",
      privilege: "CONFIGURE_SYSTEM",
      isNavDropDown: true,
      navBarGroup: "Admin",
    },
  },
  {
    path: "/packages",
    name: "Software packages",
    component: PackagesPage,
    meta: {
      title: "Software packages",
      privilege: "CONFIGURE_SYSTEM",
      isNavDropDown: true,
      navBarGroup: "Admin",
    },
  },
  {
    path: "/users/current",
    component: MyAccountPage,
    name: "Current user",
    meta: {
      title: "My Account",
      inNavBar: true,
      icon: "bi-person-circle",
      navBarGroup: "User",
    },
  },
  {
    path: "/about",
    component: AboutPage,
    name: "About",
    meta: {
      title: "About",
      inNavBar: false,
      navBarGroup: "About",
    },
  },
  {
    path: "/sign-out",
    component: SignOutPage,
    name: "SignOut",
    meta: {
      title: "You are being signed out",
      inNavBar: false,
    },
  },
];

if (AppConfig.allowPatientFileImport) {
  routes = [
    ...routes,
    {
      path: "/patients/imports",
      name: "Patient imports",
      component: PatientImportsPage,
      meta: {
        title: "Patient file imports",
        privilege: "ANY_ACTION",
        navBarGroup: "Admin",
        isNavDropDown: true,
      },
    },
  ];
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export { routes };

export default router;
