<template>
  <div class="body">
    <h2 class="mb-3">Visits</h2>
    <div class="row">
      <div class="col-9">
        <ul class="nav nav-tabs">
          <li v-for="tab in tabs" :key="tab.value" class="nav-item">
            <a
              href="#"
              :class="'nav-link' + (tab.value == selectedTab ? ' active' : '')"
              @click="setSelectedTab(tab.value)"
            >
              {{ tab.label }}
            </a>
          </li>
        </ul>
      </div>
      <div v-if="allowed('VIEW_ORGANIZATION')" class="col-3">
        <select
          ref="locationSelector"
          v-model="selectedLocation"
          class="form-select w-100"
          @change="
            () => {
              tableAttributes.offsetValue = 0;
              loadVisits();
              unFocusLocationSelector();
            }
          "
        >
          <option value="">- all clinics -</option>
          <option v-for="loc in locations" :key="loc.id" :value="loc.id">
            {{ loc.name }}
          </option>
        </select>
      </div>
    </div>

    <table class="table table-striped table-hover visit-table">
      <thead>
        <tr>
          <th>Visit ID</th>
          <th v-if="allowed('VIEW_PATIENTS')">Clinic</th>
          <th>Patient</th>
          <th v-if="showMRNForVisitList">MRN</th>
          <th v-if="['', 'needs_review', 'completed'].includes(selectedTab)">
            Started
          </th>
          <th v-if="['started'].includes(selectedTab)">Created at</th>
          <th v-if="['', 'needs_review', 'completed'].includes(selectedTab)">
            Updated
            <img src="@/assets/icons/table/sort-des.png" />
          </th>
          <th v-if="['scanned'].includes(selectedTab)">Scanned</th>
          <th v-if="['analyzing'].includes(selectedTab)">Analysis started</th>
          <th v-if="['', 'needs_review', 'completed'].includes(selectedTab)">
            Status
          </th>
          <th v-if="showGrading">DR grade (OD)</th>
          <th v-if="showGrading">DR grade (OS)</th>
          <th v-if="showGrading">Recommendation</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="v in visits"
          :key="v.id"
          role="button"
          @click="router.push('/visits/' + v.id)"
        >
          <td>
            <router-link :to="'/visits/' + v.id" @click.stop>{{
              v.id
            }}</router-link>
          </td>
          <td v-if="allowed('VIEW_PATIENTS')">
            <router-link :to="'/locations/' + v.location_id" @click.stop>
              {{ v.location_name }}
            </router-link>
          </td>
          <td v-if="allowed('VIEW_PATIENTS')">
            <router-link :to="'/patients/' + v.patient_id" @click.stop>
              {{ v.name }}
            </router-link>
          </td>
          <td v-else>{{ v.age }}, {{ v.gender }}</td>
          <td v-if="showMRNForVisitList">{{ v.mrn }}</td>
          <td
            v-if="
              ['', 'started', 'needs_review', 'completed'].includes(selectedTab)
            "
          >
            {{ formatDate(v.created) }}
          </td>
          <td v-if="['', 'needs_review', 'completed'].includes(selectedTab)">
            {{ formatDate(v.updated) }}
          </td>
          <td v-if="['scanned'].includes(selectedTab)">
            <TimeAgo :timestamp="v.updated" />
          </td>
          <td v-if="['analyzing'].includes(selectedTab)">
            <TimeAgo :timestamp="v.updated" />
          </td>
          <td v-if="['', 'needs_review', 'completed'].includes(selectedTab)">
            {{ formatStatus(v) }}
          </td>
          <td v-if="showGrading">{{ DrGrades[v.dr_grading_od] ?? "N/A" }}</td>
          <td v-if="showGrading">{{ DrGrades[v.dr_grading_os] ?? "N/A" }}</td>
          <td v-if="showGrading">{{ v.recommendation }}</td>
        </tr>
      </tbody>
    </table>
    <PageSelect
      :total-entries="totalEntries"
      :limit-per-page="tableAttributes.limitPerPage"
      :offset-value="tableAttributes.offsetValue"
      @new-offset-value="handleOffsetChange"
      @new-limit-per-page="handleLimitChange"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import backend from "@/backend";
import { formatDate, formatStatus } from "@/util/formatting";
import usePolicy from "@/util/usePolicy";
import PageSelect from "@/components/PageSelect.vue";
import { DrGrades } from "@/pages/Annotation/AnnotationGuide";
import { AppConfig } from "@/util/AppConfig";
import TimeAgo from "@/components/TimeAgo.vue";
import { useVisitsStore } from "@/store/visits";

const { allowed } = usePolicy();
const router = useRouter();
const store = useStore();
const visitsStore = useVisitsStore();

const totalEntries = ref(0);
const visits = ref([]);
const locations = ref([]);
const selectedTab = ref("");
const selectedLocation = ref("");
const showGrading = computed(() => {
  return ["needs_review", "completed"].includes(selectedTab.value);
});
const locationSelector = ref(null);
const tableAttributes = reactive({ limitPerPage: null, offsetValue: null });

const showMRNForVisitList = computed(() => AppConfig.showMRNForVisitList);

const tabs = [
  { label: "All", value: "" },
  { label: "Ready To Scan", value: "started" },
  { label: "Scanned", value: "scanned" },
  { label: "Analyzing", value: "analyzing" },
  { label: "Needs Review", value: "needs_review" },
  { label: "Completed", value: "completed" },
];

onBeforeMount(() => {
  getParamsFromURL();
  selectedTab.value =
    selectedTab.value?.trim() !== "" ? selectedTab.value : getTab();
  selectedLocation.value =
    selectedLocation.value?.trim() !== ""
      ? selectedLocation.value
      : getSelectedLocation();
  tableAttributes.limitPerPage = tableAttributes?.limitPerPage ?? getLimit();
  tableAttributes.offsetValue = tableAttributes?.offsetValue ?? getOffset();
  loadLocations();
  loadVisits();
});

function getParamsFromURL() {
  const url = new URL(window.location.href);
  const searchParams = Object.fromEntries(url.searchParams);
  for (const [key, value] of Object.entries(searchParams)) {
    if (key in tableAttributes) {
      tableAttributes[key as keyof typeof tableAttributes] = parseInt(value);
    }
    if (key === "location_id") {
      selectedLocation.value = value;
    }
    if (key === "tab") {
      selectedTab.value = value;
    }
  }
}

function computedUpdatedQuery() {
  const query: Record<string, number> = {};
  for (const [key, value] of Object.entries(tableAttributes)) {
    if (value) query[key] = value;
  }
  if (selectedLocation.value) {
    query["location_id"] = selectedLocation.value;
  }
  if (selectedTab.value) {
    query["tab"] = selectedTab.value;
  }
  router.push({ name: "Visits", query: query });
}

watch(tableAttributes, computedUpdatedQuery);
watch(selectedLocation, computedUpdatedQuery);
watch(selectedTab, computedUpdatedQuery);

function getTab(): string {
  const storedSelection = visitsStore.selection;
  if (storedSelection) return storedSelection;
  const user = store.getters.currentUser;
  if (user.roles.includes("medical_analyst")) return "scanned";
  return "";
}

function getSelectedLocation(): string {
  return visitsStore.location || "";
}

function getLimit(): number {
  const limit = visitsStore.limit ?? 20;
  return parseInt(String(limit));
}

function getOffset(): number {
  const offset =
    visitsStore.offsets.find((visit) => visit.tab === selectedTab.value)
      ?.offset ?? 0;
  return parseInt(String(offset));
}

async function loadLocations() {
  if (allowed("VIEW_ORGANIZATION")) {
    locations.value = await backend.locations.listLocations();
  }
}

function unFocusLocationSelector() {
  locationSelector.value.blur();
}

async function loadVisits() {
  const result = await backend.visits.getVisits(
    tableAttributes.limitPerPage,
    tableAttributes.offsetValue,
    selectedTab.value,
    selectedLocation.value
  );
  visits.value = result.visits;
  totalEntries.value = result.total_count;
  if (totalEntries.value !== 0 && visits.value.length === 0) {
    tableAttributes.offsetValue = 0;
    loadVisits();
  }
  visitsStore.setSelection(selectedTab.value);
  visitsStore.setLocation(selectedLocation.value);
  visitsStore.setLimit(parseInt(String(tableAttributes.limitPerPage)));
  visitsStore.setOffset({
    tab: selectedTab.value,
    offset: parseInt(String(tableAttributes.offsetValue)),
  });
}

function setSelectedTab(tab: string) {
  selectedTab.value = tab;
  tableAttributes.offsetValue = getOffset();
  loadVisits();
}

function handleLimitChange(newLimit: number) {
  tableAttributes.limitPerPage = parseInt(String(newLimit));
  tableAttributes.offsetValue = 0;
  loadVisits();
}

function handleOffsetChange(newOffset: number) {
  tableAttributes.offsetValue = parseInt(String(newOffset));
  loadVisits();
}
</script>

<style lang="scss" scoped>
a.nav-link {
  background-color: lighten(#ccd2cd, 15);
  color: rgba(#1a3d1e, 0.5);
  margin-right: 0.25rem;
  border: none;
  font-weight: bold;
}

a.nav-link:hover {
  color: #55565a;
}

a.nav-link.active {
  background-color: #ccd2cd;
  color: #1a3d1e;
}

table {
  border: 0.8rem solid #ccd2cd;
}
</style>
