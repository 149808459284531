/* eslint-disable @typescript-eslint/no-explicit-any */

export interface LocationItem {
  location_id: number;
  location_name: string;
}

export interface SoftwareChannelItem {
  sw_channel_id: number;
  sw_channel_name: string;
}

export interface OrganizationItem {
  organization_id: number;
  organization_name: string;
}

type ReferenceItem = LocationItem | SoftwareChannelItem | OrganizationItem;

export type ValueType =
  | string
  | number
  | null
  | ReferenceItem
  | (string | number | ReferenceItem)[];

export interface DetailItem {
  label: string;
  value: ValueType;
}

/**
 * A generic type guard creator for reference items
 */
function createTypeGuard<T extends ReferenceItem>(
  idKey: keyof T,
  nameKey: keyof T
): (val: unknown) => val is T {
  return (val: unknown): val is T => {
    if (val === null || typeof val !== "object") return false;

    return (
      Object.prototype.hasOwnProperty.call(val, idKey) &&
      Object.prototype.hasOwnProperty.call(val, nameKey) &&
      typeof (val as any)[idKey] === "number" &&
      typeof (val as any)[nameKey] === "string"
    );
  };
}

/**
 * Type-guard for LocationItem
 */
export const isLocationItem = createTypeGuard<LocationItem>(
  "location_id",
  "location_name"
);

/**
 * Type-guard for SoftwareChannelItem
 */
export const isSoftwareChannelItem = createTypeGuard<SoftwareChannelItem>(
  "sw_channel_id",
  "sw_channel_name"
);

/**
 * Type-guard for OrganizationItem
 */
export const isOrganizationItem = createTypeGuard<OrganizationItem>(
  "organization_id",
  "organization_name"
);
