<template>
  <BreadCrumbs
    :current="device.serial"
    parent="Devices"
    parent-path="/devices"
  />
  <div class="body">
    <DetailTable :items="deviceItems" :title="`Device ${device.serial}`">
      <template #footer>
        <button class="btn btn-oivi-light me-2" @click="editDeviceModal.show()">
          Edit
        </button>
      </template>
    </DetailTable>
  </div>
  <EditDeviceModal
    ref="editDeviceModal"
    :device="device"
    @data-change="loadData"
  />
</template>

<script setup lang="ts">
import { getDevice } from "@/backend/useDevicesAPI";
import DetailTable from "@/components/DetailTable/DetailTable.vue";
import type { DetailItem } from "@/components/DetailTable/DetailTableTypes";
import type { Device } from "@/types";
import { formatDate } from "@/util/formatting";
import { onMounted, ref } from "vue";
import EditDeviceModal from "./components/EditDeviceModal.vue";

interface Props {
  deviceID: string;
}

const props = defineProps<Props>();
const editDeviceModal = ref<InstanceType<typeof EditDeviceModal> | null>(null);
const device = ref<Device>({} as Device);
const deviceItems = ref<DetailItem[]>([]);

async function loadData(): Promise<void> {
  device.value = await getDevice(props.deviceID);
  deviceItems.value = [
    {
      label: "Serial",
      value: device.value.serial,
    },
    {
      label: "Model",
      value: device.value.hardware_model,
    },
    {
      label: "Revision",
      value: device.value.hardware_revision,
    },
    {
      label: "Software version",
      value: device.value.software_version,
    },
    {
      label: "Location",
      value: {
        location_id: device.value.location_id,
        location_name: device.value.location_name,
      },
    },
    {
      label: "Organization",
      value: {
        organization_id: device.value.organization_id,
        organization_name: device.value.organization_name,
      },
    },
    {
      label: "Software channel",
      value: {
        sw_channel_id: device.value.sw_channel_id,
        sw_channel_name: device.value.sw_channel_name,
      },
    },
    {
      label: "Last ping",
      value: formatDate(device.value.pinged),
    },
  ];
}

onMounted(async function (): Promise<void> {
  await loadData();
});
</script>
