import { AppConfig } from "@/util/AppConfig";
import {
  format,
  intlFormat,
  isThisYear,
  isToday,
  isYesterday,
  differenceInYears,
  parseISO,
  formatDistanceToNow,
} from "date-fns";

function parseDateString(dateString) {
  if (dateString.length > 23) dateString = dateString.substring(0, 23);
  dateString += "Z"; // Mark as UTC
  return parseISO(dateString);
}

function formatDate(dateString) {
  if (!dateString) return "";

  const date = parseDateString(dateString);
  const locale = "en-GB";
  const opts = {
    day: "numeric",
    month: "long",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  if (isToday(date)) return "Today, " + format(date, "HH:mm");
  if (isYesterday(date)) return "Yesterday, " + format(date, "HH:mm");
  if (isThisYear(date)) return intlFormat(date, opts, locale);

  return intlFormat(date, { year: "numeric", ...opts }, locale);
}

function formatAgeShort(dateString) {
  if (!dateString) return "";
  return differenceInYears(Date.now(), parseDateString(dateString));
}

function formatAge(dateString) {
  if (!dateString) return "No";
  const years = differenceInYears(Date.now(), parseDateString(dateString));
  if (years < 1) return "< 1 year";
  if (years == 1) return "1 year";
  return String(years) + " years";
}

function formatRelativeTime(dateString) {
  if (!dateString) return "";
  return formatDistanceToNow(parseDateString(dateString)) + " ago";
}

function formatStatus(visit) {
  if (!visit?.status) return "-";
  if (visit.status == "started") return "Ready to scan";
  if (visit.status == "scanned") return "Scanned";
  if (visit.status == "analyzing") return "Analyzing";
  if (visit.status == "analyzed" && visit.flagged) return "Needs review";
  if (visit.status == "analyzed" && !visit.flagged) return "Analyzed";
  if (visit.status == "reviewed") return "Reviewed";
  return visit.status;
}

function yearsFromDate(date) {
  if (date === null) return false;
  if (typeof date === "string") {
    const d = new Date();
    let current_year = d.getFullYear();
    return String(current_year - Number(date.slice(0, 4)));
  }
}

function yearsMonthsFromDate(date) {
  if (date === null)
    return { noHistory: true, period: { years: null, months: null } };
  const now = new Date();
  var totalMonths;
  totalMonths = (now.getFullYear() - Number(date.slice(0, 4))) * 12;
  totalMonths -= Number(date.slice(5, 7));
  totalMonths += now.getMonth() + 1;
  return {
    noHistory: false,
    period: { years: Math.floor(totalMonths / 12), months: totalMonths % 12 },
  };
}

function formatYearsMonthsOnset(onset) {
  if (onset === undefined) return "";
  const { noHistory, period } = yearsMonthsFromDate(onset);
  if (noHistory) return "N/A";
  const { years, months } = period;
  return `Yes (${years} year(s), ${months} month(s))`;
}

function dateToYYMMDD(date) {
  return date.toISOString().split("T")[0];
}

function getPositiveYearsAndMonths(years, months) {
  if (months < 0) return [years - 1, 12 + months];
  return [years, months];
}

function alignDaysWithMonths(days, months, years) {
  const lastDayInMonth = new Date(years, months + 1, 0).getDate();
  if (days > lastDayInMonth) return lastDayInMonth;
  return days;
}

function formatDateFromYearsMonthsPassed(
  years,
  months = 0,
  benchMarkDate = new Date()
) {
  const [newYears, newMonths] = getPositiveYearsAndMonths(
    benchMarkDate.getFullYear() - parseInt(years),
    benchMarkDate.getMonth() - parseInt(months)
  );
  const newDays = alignDaysWithMonths(
    benchMarkDate.getDate(),
    newMonths,
    newYears
  );
  const pastDate = new Date(
    newYears,
    newMonths,
    newDays,
    // added hours to avoid that daylight saving time always alters entire date
    // 00.00h - 1hour = previous day
    benchMarkDate.getHours()
  );

  return dateToYYMMDD(pastDate);
}

function formatCataract(cataract) {
  return cataract ? "Yes" : "N/A";
}

function userCanHaveQualifications(userRoles) {
  const usersWhoCanHaveQualifications = [
    "medical_practitioner",
    "medical_analyst",
    "sys_admin",
  ];
  return usersWhoCanHaveQualifications.some((role) =>
    userRoles?.includes(role)
  );
}

function userMustEnableMFA(userRoles) {
  if (!AppConfig.enableMFAForAdminUsers) {
    return false;
  }
  const usersWhoMustEnableMFA = [
    "medical_practitioner",
    "medical_analyst",
    "sys_admin",
  ];
  return usersWhoMustEnableMFA.some((role) => userRoles?.includes(role));
}

export function getDurationSince(dateString) {
  try {
    const localDate = parseDateString(dateString);
    return formatDistanceToNow(localDate, { addSuffix: true });
  } catch (parsingFailedError) {
    console.error(parsingFailedError);
    return "Error processing date";
  }
}

export {
  formatDate,
  formatAge,
  formatAgeShort,
  formatStatus,
  formatRelativeTime,
  yearsFromDate,
  yearsMonthsFromDate,
  formatYearsMonthsOnset,
  dateToYYMMDD,
  formatDateFromYearsMonthsPassed,
  formatCataract,
  userCanHaveQualifications,
  userMustEnableMFA,
};
