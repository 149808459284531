<template>
  <span :title="formattedDate">{{ timeAgo }}</span>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { getDurationSince } from "@/util/formatting";

interface Props {
  timestamp: string | number | Date;
  updateInterval?: number;
}

const props = withDefaults(defineProps<Props>(), {
  updateInterval: 30000, // Update every 30 seconds
});

const now = ref(new Date());
let timer: number | null = null;

const date = computed(() => new Date(props.timestamp));

const timeAgo = computed(() => getDurationSince(props.timestamp));

const formattedDate = computed(() => {
  return date.value.toLocaleString();
});

const startTimer = () => {
  timer = window.setInterval(() => {
    now.value = new Date();
  }, props.updateInterval);
};

const stopTimer = () => {
  if (timer !== null) {
    clearInterval(timer);
    timer = null;
  }
};

// Watch for changes to updateInterval and restart timer if needed
watch(
  () => props.updateInterval,
  () => {
    stopTimer();
    startTimer();
  }
);

onMounted(() => {
  startTimer();
});

onBeforeUnmount(() => {
  stopTimer();
});
</script>
