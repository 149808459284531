<template>
  <!-- Case 1: null or empty string => "-" -->
  <template v-if="isEmpty"> - </template>

  <!-- Case 2: Array => comma-joined values -->
  <template v-else-if="isArray">
    {{ formattedArray }}
  </template>

  <!-- Case 3: LocationItem => router-link to /locations/:location_id -->
  <template v-else-if="isLocationItem(value)">
    <router-link :to="`/locations/${value.location_id}`">
      {{ value.location_name }}
    </router-link>
  </template>

  <!-- Case 4: SoftwareChannelItem => router-link to /software-channels/:sw_channel_id -->
  <template v-else-if="isSoftwareChannelItem(value)">
    <router-link :to="`/channels/${value.sw_channel_id}`">
      {{ value.sw_channel_name }}
    </router-link>
  </template>

  <!-- Case 5: OrganizationItem => router-link to /organizations/:organization_id -->
  <template v-else-if="isOrganizationItem(value)">
    <router-link :to="`/organizations/${value.organization_id}`">
      {{ value.organization_name }}
    </router-link>
  </template>

  <!-- Case 6: Plain string/number -->
  <template v-else>
    {{ value }}
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import {
  isLocationItem,
  isSoftwareChannelItem,
  isOrganizationItem,
  ValueType,
} from "./DetailTableTypes";

export default defineComponent({
  name: "DetailItemComponent",
  props: {
    value: {
      type: [String, Number, Object, Array, null] as PropType<ValueType>,
      required: true,
    },
  },
  setup(props) {
    // Computed property to check if value is empty
    const isEmpty = computed(() => props.value === null || props.value === "");

    // Computed property to check if value is an array
    const isArray = computed(() => Array.isArray(props.value));

    // Computed property to format array values
    const formattedArray = computed(() => {
      if (!isArray.value) return "";
      return (props.value as (string | number)[]).join(", ");
    });

    return {
      isEmpty,
      isArray,
      formattedArray,
      isLocationItem,
      isSoftwareChannelItem,
      isOrganizationItem,
    };
  },
});
</script>
