interface VersionInfo {
  buildTag: string;
  buildDate: string;
  commitHash: string;
}

export const versionInfo: VersionInfo = {
  buildTag: "OR-2585/keyboard-shortcuts-for-image",
  buildDate: "2025-04-03T18:00:56.",
  commitHash: "5614c0b0",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).APP_VERSION_INFO = versionInfo;
export default versionInfo;
