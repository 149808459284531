import { CrashLog } from "@/types";
import axios from "axios";

const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT as string;
const activityLogsEndpoint = `${endpoint}/logs/cloud`;

interface ListCrashLogsParams {
  limit?: number;
  offset?: number;
  sort?: string;
  serial?: string;
}

export async function listCrashLogs({
  limit = 20,
  offset = 0,
  sort = "",
  serial = "",
}: ListCrashLogsParams = {}): Promise<CrashLog[]> {
  const response = await axios.get<CrashLog[]>(`${endpoint}/logs/crash`, {
    params: {
      limit,
      offset,
      sort,
      serial,
    },
  });
  return response.data;
}

export async function getCurrentCrashLog(crashId: number): Promise<CrashLog> {
  const response = await axios.get<CrashLog>(
    `${endpoint}/logs/crash/${crashId}`
  );
  return response.data;
}

export async function postCloudActivityLogs(logs): Promise<void> {
  await axios.post(activityLogsEndpoint, JSON.stringify(logs), {
    headers: { "Content-Type": "application/json" },
  });
}
