import axios from "axios";
import axiosRetry from "axios-retry";
import { showPopupMessage } from "@/util/popup";
import backend from "@/backend";

const MIN_TIMEOUT_DURATION = 5000;

function unknownUser(err) {
  if (err?.response?.status != 403) return false;

  return err?.response?.data?.message == "unknown user";
}

function forbiddenAction(err) {
  if (err?.response?.status != 403) return false;

  return err?.response?.data?.message == "forbidden action";
}

function loginRequired(err) {
  return err?.response?.status == 403 || err?.response?.status == 401;
}

function resourceNotFound(err) {
  return err?.response?.status == 404;
}

function userNotConfirmedException(err) {
  return err.toString().includes("UserNotConfirmedException");
}

function requestAborted(err) {
  return axios.isCancel(err) || err?.message == "Request aborted";
}

async function handleUncaughtException(error) {
  if (unknownUser(error)) {
    showPopupMessage(
      "You have not been granted application access. Please contact your system administrator."
    );
  } else if (forbiddenAction(error)) {
    showPopupMessage(
      "You do not have permission to perform this action. Please contact your system administrator."
    );
  } else if (resourceNotFound(error)) {
    showPopupMessage("Requested resource not found.");
  } else if (loginRequired(error)) {
    if (!(await backend.auth.userIsLoggedIn())) {
      location.href = "/sign-out";
    }
  } else if (userNotConfirmedException(error)) {
    location.href = "/sign-up-confirm";
  } else if (requestAborted(error)) {
    // This happens sometime when navigating away while
    // page is loading, in which case it is best to
    // just ignore the error.
  } else {
    let msg = error?.response?.data?.error;
    if (!msg) msg = error;
    showPopupMessage(msg);
  }
}

function shouldRetry(err) {
  if (err?.response?.status != 500) return false;

  const config = err?.response?.config;
  if (
    config &&
    "axios-retry" in config &&
    config["axios-retry"]["lastRequestTime"]
  ) {
    const duration = Date.now() - config["axios-retry"]["lastRequestTime"];
    if (duration < MIN_TIMEOUT_DURATION) return false;
  }
  return true;
}

function configureErrorHandling(app) {
  axiosRetry(axios, {
    retries: 5,
    retryCondition: shouldRetry,
  });

  app.config.errorHandler = handleUncaughtException;
  window.addEventListener("unhandledrejection", async (event) => {
    await handleUncaughtException(event.reason);
    event.preventDefault();
  });
}

export class NotImplementedError extends Error {
  constructor(message = "This functionality is not implemented") {
    super(message);
    this.name = "NotImplementedError";
  }
}

export default configureErrorHandling;
