import useAuditLogsAPI from "./useAuditLogsAPI";
import useChannelsAPI from "./useChannelsAPI";
import * as useCognitoAPI from "./useCognitoAPI";
import useContactAPI from "./useContactAPI";
import useDevicesAPI from "./useDevicesAPI";
import useImagesAPI from "./useImagesAPI";
import useLocationsAPI from "./useLocationsAPI";
import * as useLogsAPI from "./useLogsAPI";
import useNativeAuthAPI from "./useNativeAuthAPI";
import useOrganizationsAPI from "./useOrganizationsAPI";
import usePackagesAPI from "./usePackagesAPI";
import usePatientsAPI from "./usePatientsAPI";
import useSettingsAPI from "./useSettingsAPI";
import useStatisticsAPI from "./useStatisticsAPI";
import useUsersAPI from "./useUsersAPI";
import useVisitsAPI from "./useVisitsAPI";
import { AppConfig } from "@/util/AppConfig";

const authBackend = AppConfig.useNativeAuth
  ? useNativeAuthAPI()
  : useCognitoAPI;

export default {
  auth: authBackend,
  channels: useChannelsAPI(),
  devices: useDevicesAPI(),
  images: useImagesAPI(),
  locations: useLocationsAPI(),
  logs: useLogsAPI,
  organizations: useOrganizationsAPI(),
  packages: usePackagesAPI(),
  patients: usePatientsAPI(),
  settings: useSettingsAPI(),
  users: useUsersAPI(),
  visits: useVisitsAPI(),
  contact: useContactAPI(),
  statistics: useStatisticsAPI(),
  audit: useAuditLogsAPI(),
};
