/**
 * All config related computations, functions to be placed in here.
 * As of now each region is to be considered as separate tenant & as such it
 * could have a separate config. Every tenant specific value / feature / change
 * needs to be handled through config functions here.
 */
const region = import.meta.env.VITE_REGION?.toLowerCase() ?? "in";
const deploymentEnv = import.meta.env.VITE_DEPLOYMENT;
const isProduction = import.meta.env.NODE_ENV === "production";
const useNativeAuth = Boolean(import.meta.env.VITE_NATIVE_AUTH) || false;

export const AppConfig = {
  region,
  getVisitRecommendation(currVisit) {
    switch (region) {
      case "us":
        return currVisit.recommendation_with_return;
      default:
        return currVisit.recommendation;
    }
  },
  get countryPhoneCode() {
    switch (region) {
      case "us":
        return "US";
      case "vn":
        return "VN";
      default:
        return "IN";
    }
  },
  allowPatientFileImport: region === "us",
  organizationNeedSiteID: region === "us",
  showMRNForPatientList: region === "us",
  showIDForPatientList: region !== "us",
  showMRNForVisitList: region !== "us",
  get enableMFAForAdminUsers() {
    const enableMFAVal = (
      import.meta.env.VITE_ENABLE_MFA ?? "False"
    )?.toLowerCase();
    return enableMFAVal === "true";
  },
  isProduction,
  deploymentEnv,
  useNativeAuth,
  imageMetadataNeedsParsing: region !== "vn",
};
