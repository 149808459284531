import { defineStore } from "pinia";

interface VisitOffset {
  tab: string;
  offset: number;
}

interface VisitsState {
  selection: string | undefined;
  location: string | undefined;
  limit: number | undefined;
  offsets: VisitOffset[];
}

export const useVisitsStore = defineStore("visits", {
  state: (): VisitsState => ({
    selection: undefined,
    location: undefined,
    limit: undefined,
    offsets: [],
  }),

  actions: {
    setSelection(selection: string | undefined) {
      this.selection = selection;
    },

    setLocation(location: string | undefined) {
      if (location === this.location) return;
      this.location = location;
      this.offsets = [];
    },

    setLimit(limit: number | undefined) {
      if (limit === this.limit) return;
      this.limit = limit;
      this.offsets = [];
    },

    setOffset(payload: VisitOffset) {
      this.offsets = [
        ...this.offsets.filter((item) => item?.tab !== payload?.tab),
        payload,
      ];
    },
  },
});
