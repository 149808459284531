<template>
  <tr>
    <th>{{ item.label }}</th>
    <td><DetailItemComponent :value="item.value" /></td>
  </tr>
</template>

<script setup lang="ts">
import DetailItemComponent from "./DetailItemComponent.vue";
import type { DetailItem } from "./DetailTableTypes";
defineProps<{ item: DetailItem }>();
</script>
