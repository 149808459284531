<template>
  <div class="body">
    <h3 v-if="title" class="mb-2">{{ title }}</h3>
    <table class="table table-bordered">
      <tbody>
        <template v-if="items && items.length">
          <DetailTableRow v-for="(i, idx) in items" :key="idx" :item="i" />
        </template>
        <tr v-if="$slots.footer">
          <td colspan="2">
            <slot name="footer"></slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import DetailTableRow from "./DetailTableRow.vue";
import type { DetailItem } from "./DetailTableTypes";

defineProps({
  title: {
    type: String,
    default: "",
  },
  items: {
    type: Array as () => DetailItem[],
    default: () => [],
  },
});
</script>
