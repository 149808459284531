<template>
  <BreadCrumbs :current="user.email" parent="Users" parent-path="/users" />
  <div class="body">
    <DetailTable :title="user.name ?? ''" :items="userDetails">
      <template #footer>
        <button class="btn btn-oivi-light me-2" @click="showEdit">Edit</button>
        <button
          v-if="user.email && !user.last_login"
          class="btn btn-oivi-light"
          @click="showDeleteDialog"
        >
          Delete
        </button>
      </template>
    </DetailTable>
  </div>
  <UserDetailsModal
    v-if="editVisible"
    :user-i-d="props.userID"
    :roles="roles"
    :user="user"
    @close="hideEdit"
    @change="loadUser"
  />
  <ModalForm
    v-show="deleteVisible"
    title="Delete user?"
    @close="hideDeleteDialog"
  >
    <p>Please confirm that you want to delete this user:</p>
    <p>
      <b>{{ user.email }}</b>
    </p>
    <div class="mt-5">
      <button class="btn btn-danger me-2" @click="doDelete">Confirm</button>
      <button class="btn btn-secondary" @click="hideDeleteDialog">
        Cancel
      </button>
    </div>
  </ModalForm>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import UserDetailsModal from "./components/UserDetailsModal.vue";
import DetailTable from "@/components/DetailTable/DetailTable.vue";
import type { DetailItem } from "@/components/DetailTable/DetailTableTypes";

const store = useStore();
const router = useRouter();
const props = defineProps(["userID"]);

const user = ref({});
const editVisible = ref(false);
const deleteVisible = ref(false);
const userDetails = ref<DetailItem[]>([]);

const me = store.getters.currentUser;
const roles = computed(() => backend.users.getAvailableRoles(me));

function showEdit() {
  editVisible.value = true;
}

function hideEdit() {
  editVisible.value = false;
}

async function loadUser() {
  const userData = await backend.users.getUser(props.userID);
  user.value = userData;
  userDetails.value = [
    {
      label: "Name",
      value: userData.name,
    },
    {
      label: "Email",
      value: userData.email,
    },
    {
      label: "Phone",
      value: userData.phone,
    },
    {
      label: "Organization",
      value: {
        organization_id: userData.organization_id,
        organization_name: userData.organization,
      },
    },
    {
      label: "Roles",
      value: userData.roles,
    },
    {
      label: "Qualifications",
      value: userData.qualifications,
    },
    {
      label: "Last login",
      value: userData.last_login ?? "Never",
    },
  ];
}

function showDeleteDialog() {
  deleteVisible.value = true;
}

function hideDeleteDialog() {
  deleteVisible.value = false;
}

async function doDelete() {
  await backend.users.deleteUser(props.userID);
  deleteVisible.value = false;
  router.push("/users");
}

onMounted(async function () {
  await loadUser();
});
</script>
