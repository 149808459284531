import { getFormattedDateUTC } from "@/util/logging";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import backend from "@/backend";

const LOG_THRESHOLD = 10;

const state = {
  currentUser: undefined,
  activityLogs: [],
  targetUrl: { history: false, path: "/" },
  lastViewedReport: undefined,
  patientsListLimit: undefined,
  usersListLimit: undefined,
  patientsListOffset: undefined,
  patientsListSearch: undefined,
  patientsListSortBy: undefined,
  usersListOffset: undefined,
};

const getters = {
  currentUser: (state) => state.currentUser,
};

const actions = {
  addActivityLog({ commit, state }, log) {
    return new Promise((resolve, reject) => {
      const updatedLogList = [
        ...state.activityLogs,
        { ...log, timestamp: getFormattedDateUTC() },
      ];

      // Only try to post if we have at least LOG_THRESHOLD logs to send
      if (updatedLogList.length >= LOG_THRESHOLD) {
        backend.logs
          .postActivityLogs(updatedLogList)
          .then(() => {
            commit("CLEAR_ACTIVITY_LOGS");
            resolve();
          })
          .catch((error) => {
            console.warn("Could not upload logs. Will try again later.");
            reject(error);
          });
      } else {
        commit("UPDATE_ACTIVITY_LOGS", updatedLogList);
        resolve();
      }
    });
  },
};
const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  UPDATE_ACTIVITY_LOGS(state, updatedLogList) {
    state.activityLogs = updatedLogList;
  },
  CLEAR_ACTIVITY_LOGS(state) {
    state.activityLogs = [];
  },
  SET_TARGET_URL(state, payload) {
    state.targetUrl = payload;
  },
  SET_LAST_VIEWED_REPORT(state, payload) {
    if (state.lastViewedReport) URL.revokeObjectURL(state.lastViewedReport);
    state.lastViewedReport = payload;
  },
  SET_PATIENTS_LIST_LIMIT(state, payload) {
    if (payload == state.patientsListLimit) return;
    state.patientsListLimit = payload;
  },
  SET_USERS_LIST_LIMIT(state, payload) {
    if (payload == state.usersListLimit) return;
    state.usersListLimit = payload;
  },
  SET_USERS_LIST_OFFSET(state, payload) {
    if (payload == state.usersListOffset) return;
    state.usersListOffset = payload;
  },
  SET_PATIENTS_LIST_OFFSET(state, payload) {
    if (payload == state.patientsListOffset) return;
    state.patientsListOffset = payload;
  },
  SET_PATIENTS_LIST_SEARCH(state, payload) {
    if (payload == state.patientsListSearch) return;
    state.patientsListSearch = payload;
  },
  SET_PATIENTS_LIST_SORT_BY(state, payload) {
    if (payload == state.patientsListSortBy) return;
    state.patientsListSortBy = payload;
  },
};
export { state, getters, actions, mutations };

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ["currentUser", "targetUrl", "activityLogs"],
    }),
  ],
  state,
  getters,
  actions,
  mutations,
});
