import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "bootstrap";
import "@/style/style.scss";
import "intl-tel-input/build/css/intlTelInput.css";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import configureAuth from "@/util/configure_auth";
import { configureSentry } from "@/util/sentry";
import configureErrorHandling from "./util/error_handling";
import VueDiff from "vue-diff";
import "vue-diff/dist/index.css";
import { createSentryPiniaPlugin } from "@sentry/vue";
import { versionInfo } from "./util/versionHelper";

console.table(versionInfo);

configureAuth();

const pinia = createPinia();
const app = createApp(App);

pinia.use(createSentryPiniaPlugin());
configureSentry(app, router);

app.use(store);
app.use(router);
app.use(VueDiff);
app.use(pinia);
app.component("BreadCrumbs", BreadCrumbs);

const isDev = import.meta.env.DEV;
if (!isDev) {
  configureErrorHandling(app);
}

app.mount("#app");
