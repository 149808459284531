<template>
  <div class="report-container">
    <div v-if="isLoading" class="loading-indicator">
      <p class="mt-2">Loading report...</p>
    </div>
    <div v-else-if="error" class="error-message">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
    <iframe
      v-else
      :src="reportUrl"
      width="100%"
      height="calc(100% - 50px)"
      style="border: none"
    ></iframe>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import backend from "@/backend";
import { useStore } from "vuex";

interface Props {
  visitID: string;
  fullScreenView?: boolean;
}

const props = defineProps<Props>();
const store = useStore();
const reportUrl = ref(null);
const isLoading = ref(true);
const error = ref(null);

async function logViewReport() {
  const userId = store.state?.currentUser?.id;
  await backend.audit.logUserAction(
    userId,
    backend.audit.userActionTypes.VIEW_REPORT,
    props.visitID
  );
}

async function viewReport() {
  try {
    isLoading.value = true;
    const params = props.fullScreenView ? { "full-screen-view": true } : {};
    const result = await backend.visits.downloadReport(props.visitID, params);
    await logViewReport();
    const url = URL.createObjectURL(result);
    store.commit("SET_LAST_VIEWED_REPORT", url);
    reportUrl.value = url;
    isLoading.value = false;
  } catch (err) {
    console.error("Error loading report:", err);
    error.value = "Failed to load report. Please try again later.";
    isLoading.value = false;
  }
}

onMounted(async () => {
  await viewReport();
});
</script>

<style scoped>
.report-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  z-index: 1030;
  overflow: hidden;
}

.loading-indicator,
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

iframe {
  height: 100%;
}
</style>
