import axios from "axios";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { AppConfig } from "./AppConfig";
import { NativeAuthStore } from "@/backend/NativeAuthStore";
import { invokeRefreshToken, invokeSignOut } from "@/backend/useNativeAuthAPI";

function amplifyAuthConfiguration() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID,
        userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
        signUpVerificationMethod: "link",
        loginWith: {
          email: true,
        },
      },
    },
  });

  axios.interceptors.request.use(async function (config) {
    if (
      config.url &&
      config.url.startsWith(import.meta.env.VITE_BIRDHOUSE_ENDPOINT)
    ) {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.accessToken;
        config.headers.Authorization = token;
      } catch (_err) {
        // empty
      }
    }
    return config;
  });
}

const API_PREFIX = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;
const AUTH_PREFIX = `${API_PREFIX}/auth`;

function nativeAuthconfiguration() {
  axios.interceptors.request.use(async function (config) {
    if (
      config.url &&
      !config.url.startsWith(AUTH_PREFIX) &&
      NativeAuthStore.hasTokenExpired()
    ) {
      await invokeRefreshToken();
    }

    if (
      config.url &&
      config.url.startsWith(import.meta.env.VITE_BIRDHOUSE_ENDPOINT)
    ) {
      try {
        config.withCredentials = true;
      } catch (_err) {
        // empty
      }
    }
    return config;
  });

  axios.interceptors.response.use(
    (res) => res,
    async function (err) {
      if (err?.response?.status === 401) {
        await invokeSignOut();
      }
    }
  );
}

const configureAuth = AppConfig.useNativeAuth
  ? nativeAuthconfiguration
  : amplifyAuthConfiguration;

export default configureAuth;
