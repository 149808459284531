export const DrGrades = {
  "Grade 1: No DR": "Grade 0: no apparent retinopathy",
  "Grade 2: Mild NPDR": "Grade 1: mild NPDR",
  "Grade 3: Moderate NPDR": "Grade 2: moderate NPDR",
  "Grade 4: Severe NPDR": "Grade 3: severe NPDR",
  "Grade 5: PDR": "Grade 4: proliferative diabetic Retinopathy",
  Inconclusive: "Inconclusive",
  AI_MTMDR: "More than mild Non-proliferative DR",
};

export const analystRecommendations = [
  "None",
  "Rescan",
  "Follow up in 12 months",
  "Follow up in 6 months",
  "Follow up in 3 months",
  "Follow up in 1 month",
  "Refer to specialist",
  "Refer to Ophthalmologist",
];
