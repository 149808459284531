import axios from "axios";

import type { Device } from "@/types";

const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

export async function listDevices(sortBy: string = ""): Promise<Device[]> {
  const res = await axios.get(`${endpoint}/devices`, {
    params: { sort: sortBy },
  });
  return res.data.devices;
}

export async function getDevice(device_serial: string): Promise<Device> {
  return (await axios.get(`${endpoint}/devices/` + device_serial)).data.device;
}

export async function updateDevice(
  serial: string,
  data: Partial<Device>
): Promise<Device> {
  return axios.put(`${endpoint}/devices/${serial}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
}

function useDevicesAPI() {
  function updateDevice(
    serial: string,
    data: Partial<Device>
  ): Promise<Device> {
    return axios.put(`${endpoint}/devices/${serial}`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  return {
    updateDevice,
  };
}

export default useDevicesAPI;
