<template>
  <BreadCrumbs :current="props.logID" parent="Logs" parent-path="/logs/crash" />

  <div class="body container-fluid">
    <DetailTable
      :items="errorItems"
      :title="`Crash ${currentLogData?.id} details`"
    >
      <template #footer>
        <a
          v-if="currentLogData && currentLogData.s3_path"
          :href="currentLogData.s3_path"
          target="_blank"
          class="btn btn-oivi-light me-2"
        >
          Download Log File
        </a>
      </template>
    </DetailTable>
  </div>
</template>

<script setup lang="ts">
import { getCurrentCrashLog } from "@/backend/useLogsAPI";
import { CrashLog } from "@/types";
import { onMounted, ref } from "vue";
import DetailTable from "@/components/DetailTable/DetailTable.vue";
import type { DetailItem } from "@/components/DetailTable/DetailTableTypes";

interface Props {
  logID: string;
}

const props = defineProps<Props>();
const currentLogData = ref<CrashLog | null>(null);
const error = ref<string | null>(null);
const errorItems = ref<DetailItem[]>([]);

async function loadData(): Promise<void> {
  try {
    const logData = await getCurrentCrashLog(+props.logID);
    currentLogData.value = logData;
    errorItems.value = [
      {
        label: "ID",
        value: logData.id,
      },
      {
        label: "Created",
        value: new Date(logData.created).toLocaleString(),
      },
      {
        label: "Organization",
        value: {
          organization_id: logData.organization_id,
          organization_name: logData.organization_name,
        },
      },
      {
        label: "Location",
        value: {
          location_id: logData.location_id,
          location_name: logData.location_name,
        },
      },
      {
        label: "Device Model",
        value: logData.device_model ?? "N/A",
      },
      {
        label: "Device Revision",
        value: logData.device_revision ?? "N/A",
      },
      {
        label: "Device Serial",
        value: logData.device_serial ?? "N/A",
      },
    ];
  } catch (err) {
    error.value =
      err instanceof Error ? err.message : "Failed to load crash log";
    console.error("Failed to load crash log:", err);
  }
}

onMounted(async () => loadData());
</script>
