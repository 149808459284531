<template>
  <SplashCard @on-enter="handleEnterKeyUp">
    <h2 class="text-uppercase text-center mb-5">Create account</h2>
    <p>Make sure an account holder has added you to acquire access</p>
    <form>
      <div class="form-outline mb-4">
        <input
          id="form3Example3cg"
          v-model="userData.email"
          type="email"
          class="form-control form-control-lg"
          :class="[
            isEmailValid
              ? 'border-success'
              : userData.email === ''
                ? ''
                : 'border-danger',
          ]"
          placeholder="Email"
          :readonly="!!invitationCode"
          :disabled="!!invitationCode"
        />
      </div>

      <div class="form-outline mb-4">
        <input
          id="form3Example1cg"
          v-model="userData.fullName"
          type="text"
          class="form-control form-control-lg"
          placeholder="Full Name"
        />
      </div>

      <div class="form-outline mb-4">
        <div id="hoverText">
          <img
            title="Password should contain atleast 1 lowercase ,1 uppercase alphabet, 1 number and should be atleast 8 characters long."
            src="https://img.icons8.com/material-outlined/24/000000/info.png"
          />
        </div>
        <input
          id="form3Example4cg"
          v-model="userData.password"
          :type="[isPasswordVisible ? 'text' : 'password']"
          class="form-control form-control-lg"
          :class="[
            isPasswordValid
              ? 'border-success'
              : userData.password === ''
                ? ''
                : 'border-danger',
          ]"
          placeholder="Password"
        />
        <div class="mt-1 text-start">
          <input
            v-model="isPasswordVisible"
            type="checkbox"
            class="btn"
            name="Show Password"
          />
          show password
        </div>
      </div>

      <div class="form-outline mb-4">
        <input
          id="form3Example4cdg"
          v-model="userData.confirmPassword"
          :type="[isPasswordVisible ? 'text' : 'password']"
          class="form-control form-control-lg"
          :class="[
            userData.password === userData.confirmPassword &&
            isPasswordValid === true
              ? 'border-success'
              : userData.confirmPassword === ''
                ? ''
                : 'border-danger',
          ]"
          placeholder="Confirm Password"
        />
      </div>

      <div class="form-outline mb-4" @click="phoneNumberValidation">
        <input
          id="phone"
          v-model="userData.phoneNumber"
          type="tel"
          class="form-control form-control-lg"
          :class="[
            isPhoneNumberValid === true
              ? 'border-success'
              : userData.phoneNumber === ''
                ? ''
                : 'border-danger',
          ]"
        />
      </div>

      <div class="form-check d-flex justify-content-center mb-5">
        <input
          id="form2Example3cg"
          v-model="isTermsOfServiceAccepted"
          class="form-check-input me-2"
          type="checkbox"
          name="accept terms"
        />
        <label class="form-check-label" for="form2Example3g">
          I agree to all statements in the
          <a
            href="#"
            class="link fw-bold"
            data-bs-toggle="modal"
            data-bs-target="#tosModal"
          >
            Terms of Service
          </a>
          <TermsOfServiceModal />
        </label>
      </div>
      <div v-if="signUpError" class="alert alert-danger">
        {{ signUpError }}
      </div>

      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-oivi-light btn-lg px-5"
          :disabled="inProgress"
          @click="signUp_"
        >
          <div
            v-if="inProgress"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-if="!inProgress">Sign Up</span>
        </button>
      </div>

      <p class="text-center text-muted mt-5 mb-0">
        Have already an account?
        <a class="fw-bold link" @click="goToSignInPage">Login here</a>
      </p>
    </form>
  </SplashCard>
</template>
<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import intlTelInput from "intl-tel-input";
import backend from "@/backend";
import { validateEmail, validatePassword } from "@/util/validation";

import { AppConfig } from "@/util/AppConfig";
import SplashCard from "@/components/SplashCard.vue";
import TermsOfServiceModal from "./TermsOfServiceModal.vue";

const router = useRouter();
const store = useStore();

const route = useRoute();
const invitationCodeParam = route.params.code ?? "";
const invitationCodeValue = atob(invitationCodeParam) ?? "";
const [invitationCode = "", email = ""] = invitationCodeValue.split("::");
console.log(invitationCode, email);

const isEmailValid = ref(email?.trim() !== "");
const isPasswordValid = ref(false);
const isPasswordVisible = ref(false);
const isPhoneNumberValid = ref(false);
const isTermsOfServiceAccepted = ref(false);

const inProgress = ref(false);
const signUpError = ref("");

const userData = reactive({
  fullName: "",
  email: email,
  password: "",
  confirmPassword: "",
  phoneNumber: "",
});
var iti;

onMounted(() => {
  const input = document.querySelector("#phone");
  iti = intlTelInput(input, {
    initialCountry: AppConfig.countryPhoneCode,
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.0/js/utils.js",
  });
});

function phoneNumberValidation() {
  isPhoneNumberValid.value = iti.isValidNumber();
}

watch(
  () => userData.email,
  function (newValue) {
    isEmailValid.value = validateEmail(newValue);
  }
);
watch(
  () => userData.password,
  function (newValue) {
    isPasswordValid.value = validatePassword(newValue);
  }
);
watch(() => userData.phoneNumber, phoneNumberValidation);

async function signUp_() {
  inProgress.value = true;
  signUpError.value = "";
  const userDataObj = {
    ...userData,
    phoneNumber: iti.getNumber(),
  };
  var errorMessage = "";

  if (userDataObj.fullName === "") {
    errorMessage += "Name cannot be empty\n";
  }
  if (!isEmailValid.value) {
    errorMessage += "Invalid email\n";
  }
  if (!isPasswordValid.value) {
    errorMessage +=
      "Invalid Password : should contain atleast 1 lowercase ,1 uppercase alphabet, 1 number and should be atleast 8 characters long\n";
  }
  if (userDataObj.confirmPassword !== userDataObj.password) {
    errorMessage += "Your password and confirmation password do not match\n";
  }
  if (!isPhoneNumberValid.value && userDataObj.phoneNumber !== "") {
    errorMessage += "Invalid Phone Number\n";
  }
  if (!isTermsOfServiceAccepted.value) {
    errorMessage += "Please Accept Terms of Service\n";
  }

  if (errorMessage !== "") {
    inProgress.value = false;
    return;
  }
  try {
    if (invitationCode) {
      await backend.auth.signUp(
        invitationCode,
        userDataObj.password,
        userDataObj.fullName,
        userDataObj.phoneNumber
      );
      // store.commit("SET_CURRENT_USER", { email: response.user.username });
      router.push({ name: "Home" });
    } else {
      await backend.auth.signUp(
        userDataObj.email,
        userDataObj.password,
        userDataObj.fullName,
        userDataObj.phoneNumber
      );
      store.commit("SET_CURRENT_USER", { email: userDataObj.email });
      router.push({ name: "SignUpConfirm" });
    }
  } catch (error) {
    console.error(error.message);
    signUpError.value = error.message;
  }
  inProgress.value = false;
}

function goToSignInPage() {
  router.push({ name: "SignIn" });
}

function handleEnterKeyUp() {
  if (inProgress.value) return;
  signUp_();
}
</script>

<style scoped>
.link {
  cursor: pointer;
}

#hoverText {
  position: absolute;
  right: 60px;
  padding-top: 0.5rem;
}

#phone {
  margin-right: 200px;
}
</style>
