const TOKEN_EXPIRE_KEY = "token_expiry";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function setOrUpdateCookie(key, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = key + "=" + (value || "") + expires + "; path=/";
}

const deleteCookie = (key) => setOrUpdateCookie(key, "", -10);

export const NativeAuthStore = {
  updateAuth(responseData) {
    setOrUpdateCookie(TOKEN_EXPIRE_KEY, responseData.exp);
  },
  hasTokenExpired() {
    const expirationTime = getCookie(TOKEN_EXPIRE_KEY);
    if (!expirationTime) {
      return true;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiration = expirationTime - currentTime;
    return timeUntilExpiration < 2000;
  },
  logout() {
    [TOKEN_EXPIRE_KEY].forEach(deleteCookie);
  },
};
