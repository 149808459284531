import axios from "axios";

const apiBase = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

export async function downloadReport(visitID, params = {}) {
  const endpoint = `${apiBase}/visits/${visitID}/report`;

  return (
    await axios.get(endpoint, {
      params: params,
      responseType: "blob",
    })
  ).data;
}

export async function getVisitsStatistics(
  locationId = "",
  sortBy = "",
  start_date = "",
  end_date = ""
) {
  const res = await axios.get(`${apiBase}/visits/statistics`, {
    params: {
      location_id: locationId,
      sort: sortBy,
      start_date: start_date,
      end_date: end_date,
    },
  });
  return res.data.visits_statistics;
}

function useVisitsAPI() {
  async function getVisit(visitID, params = {}) {
    return (await axios.get(`${apiBase}/visits/${visitID}`, { params })).data;
  }

  async function getVisits(
    limit = 20,
    offset = 0,
    status = "",
    location_id = ""
  ) {
    return (
      await axios.get(`${apiBase}/visits`, {
        params: {
          limit,
          offset,
          status,
          location_id,
        },
      })
    ).data;
  }

  async function downloadVisitStatistics(
    locationId = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    host_link = ""
  ) {
    return await axios.get(`${apiBase}/visits/statistics/download`, {
      params: {
        location_id: locationId,
        sort: sortBy,
        start_date: start_date,
        end_date: end_date,
        host_link: host_link,
      },
      responseType: "blob",
    });
  }

  async function getVisitImages(visitID, limit = 20, offset = 0) {
    return (
      await axios.get(`${apiBase}/visits/${visitID}/images`, {
        params: {
          limit: limit,
          offset: offset,
        },
      })
    ).data.images;
  }

  function deleteVisit(visitID) {
    return axios.delete(`${apiBase}/visits/${visitID}`);
  }

  function beginAnalysis(visitID) {
    return axios.post(`${apiBase}/visits/${visitID}/analysis`);
  }

  function submitAnalysis(
    visitID,
    dr_grade_od,
    dme_od,
    htr_od,
    glaucoma_od,
    dr_grade_os,
    dme_os,
    htr_os,
    glaucoma_os,
    recommendation,
    odImage,
    osImage
  ) {
    return axios.post(
      `${apiBase}/visits/${visitID}/submission`,
      JSON.stringify({
        dr_grading_od: dr_grade_od ?? null,
        dme_od: dme_od ?? null,
        htr_od: htr_od ?? null,
        glaucoma_od: glaucoma_od ?? null,
        dr_grading_os: dr_grade_os ?? null,
        dme_os: dme_os ?? null,
        htr_os: htr_os ?? null,
        glaucoma_os: glaucoma_os ?? null,
        recommendation: recommendation,
        selected_od_image: odImage ?? null,
        selected_os_image: osImage ?? null,
      }),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  function markReviewed(visitID) {
    return axios.post(`${apiBase}/visits/${visitID}/review`);
  }

  async function resendReport(visitID) {
    return axios.post(`${apiBase}/visits/${visitID}/report`);
  }

  function addNote(visitID, note) {
    return axios.post(
      `${apiBase}/visits/${visitID}/notes`,
      JSON.stringify(note),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async function getNotes(visitID) {
    return (await axios.get(`${apiBase}/visits/${visitID}/notes`)).data;
  }

  function updateNote(noteID, note) {
    return axios.put(`${apiBase}/visit_notes/${noteID}`, JSON.stringify(note), {
      headers: { "Content-Type": "application/json" },
    });
  }

  function deleteNote(noteID) {
    return axios.delete(`${apiBase}/visit_notes/${noteID}`);
  }

  return {
    getVisit,
    getVisits,
    getVisitsStatistics,
    downloadVisitStatistics,
    getVisitImages,
    beginAnalysis,
    submitAnalysis,
    markReviewed,
    deleteVisit,
    downloadReport,
    addNote,
    getNotes,
    updateNote,
    deleteNote,
    resendReport,
  };
}

export default useVisitsAPI;
