<template>
  <div class="body container-fluid">
    <p>
      You are using version
      <span id="ui-version">{{ versionInfo.buildTag }}</span> of the Oivi cloud.
    </p>
    <p>
      If you have feeback or questions, please contact us with the form on the
      <router-link to="/contact"> Contact page</router-link>.
    </p>
    <p>
      For more information about Oivi, please visit our
      <a href="https://www.oivi.co">website</a>.
    </p>
  </div>
</template>
<script setup lang="ts">
import { versionInfo } from "@/util/versionHelper";
</script>
<style>
#ui-version {
  font-weight: bold;
}
</style>
