/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { NativeAuthStore } from "./NativeAuthStore";
import { NotImplementedError } from "@/util/error_handling";

const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

let refreshinTokenPromise = null;
async function refreshAccessToken() {
  const res = await axios.post(`${endpoint}/auth/token-refresh`);
  NativeAuthStore.updateAuth(res.data);
  refreshinTokenPromise = null;
  return res;
}

export async function invokeRefreshToken() {
  if (!refreshinTokenPromise) {
    refreshinTokenPromise = refreshAccessToken();
  }
  return refreshinTokenPromise;
}

export async function invokeSignOut() {
  await axios.post(`${endpoint}/auth/logout`);
  NativeAuthStore.logout();
  localStorage.clear();
  window.location.reload();
}

function useAuthAPI() {
  async function signUp(invitationCode, password, name, phoneNumber) {
    const res = await axios.post(
      `${endpoint}/auth/accept-invitation/${invitationCode}`,
      {
        name: name,
        password,
        phone_number: phoneNumber,
      }
    );
    NativeAuthStore.updateAuth(res.data);
    return res;
  }

  function confirmSignUp(email, code) {
    throw new NotImplementedError();
  }

  function resendConfirmationCode(email) {
    throw new NotImplementedError();
  }

  async function signIn(email, password) {
    const res = await axios.post(`${endpoint}/auth/login`, { email, password });
    NativeAuthStore.updateAuth(res.data);
    return true;
  }

  function confirmSignIn(user, totpCode) {
    throw new NotImplementedError();
  }

  function setupTOTP(user) {
    throw new NotImplementedError();
  }

  function verifyTOTP(user, totp) {
    throw new NotImplementedError();
  }

  function currentAuthenticatedUser() {
    throw new NotImplementedError();
  }

  function getPreferredMFA(user) {
    throw new NotImplementedError();
  }

  function setPreferredMFA(user, mfaMethod) {
    throw new NotImplementedError();
  }

  async function signOut() {
    invokeSignOut();
  }

  function forgotPassword(email) {
    throw new NotImplementedError();
  }

  function forgotPasswordSubmit(email, code, newPassword) {
    throw new NotImplementedError();
  }

  async function userIsLoggedIn() {
    return !NativeAuthStore.hasTokenExpired();
  }

  return {
    signUp,
    confirmSignUp,
    resendConfirmationCode,
    signIn,
    confirmSignIn,
    setupTOTP,
    verifyTOTP,
    currentAuthenticatedUser,
    getPreferredMFA,
    setPreferredMFA,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    userIsLoggedIn,
  };
}

export default useAuthAPI;
