<template>
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Devices</h2>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th @click="sortBy('serial')">
            Serial number
            <SortIcon col="serial" :sorting="tableAttributes.sortBy" />
          </th>
          <th>Model</th>
          <th>Revision</th>
          <th role="button" @click="sortBy('software_version')">
            SW version
            <SortIcon
              col="software_version"
              :sorting="tableAttributes.sortBy"
            />
          </th>
          <th>Location</th>
          <th>Organization</th>
          <th>SW channel</th>
          <th role="button" @click="sortBy('pinged')">
            Last ping
            <SortIcon col="pinged" :sorting="tableAttributes.sortBy" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="device in devices"
          :key="device.serial"
          role="button"
          @click="router.push('/device/' + device.serial)"
        >
          <td>{{ device.serial }}</td>
          <td>{{ device.hardware_model }}</td>
          <td>{{ device.hardware_revision }}</td>
          <td>{{ device.software_version }}</td>
          <td>
            <router-link :to="'/locations/' + device.location_id">
              {{ device.location_name }}
            </router-link>
          </td>
          <td>
            <router-link :to="'/organizations/' + device.organization_id">
              {{ device.organization_name }}
            </router-link>
          </td>
          <td>
            <router-link :to="'/channels/' + device.sw_channel_id">
              {{ device.sw_channel_name }}
            </router-link>
          </td>
          <td>{{ formatRelativeTime(device.pinged) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup lang="ts">
import { listDevices } from "@/backend/useDevicesAPI";
import SortIcon from "@/components/SortIcon.vue";
import { useDeviceStore } from "@/store/devices";
import { formatRelativeTime } from "@/util/formatting";
import { onBeforeMount, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const devices = ref([]);
const deviceStore = useDeviceStore();
const tableAttributes = reactive({ sortBy: null });

async function loadData() {
  devices.value = await listDevices(tableAttributes.sortBy);
}

function setTableFilters(filters) {
  for (const [key, value] of Object.entries(filters)) {
    if (key in tableAttributes) {
      tableAttributes[key] = value;
    }
  }
}

watch(tableAttributes, () => {
  const query = {};
  for (const [key, value] of Object.entries(tableAttributes)) {
    if (value) query[key] = value;
  }
  router.push({ name: "Devices", query: query });
  loadData();
});

onBeforeMount(() => {
  const url = new URL(window.location);
  setTableFilters(Object.fromEntries(url.searchParams));
  tableAttributes.sortBy = tableAttributes?.sortBy ?? getSort();
  loadData();
});

function sortBy(col) {
  if (tableAttributes.sortBy == `+${col}`) {
    tableAttributes.sortBy = `-${col}`;
  } else {
    tableAttributes.sortBy = `+${col}`;
  }
  deviceStore.updateSort(tableAttributes.sortBy);
}

function getSort() {
  const sort = deviceStore.sortBy ?? "-serial";
  return sort;
}
</script>
