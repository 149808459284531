<template>
  <div class="body">
    <div class="d-flex mb-3">
      <h2>Users</h2>
      <div class="ms-auto">
        <button class="btn btn-oivi-light" @click="showAddUserModal">
          Add user
        </button>
      </div>
    </div>

    <div v-if="allowed('ACCESS_ALL_ORGANIZATIONS')" class="row mb-2">
      <div class="col-3">
        <input
          v-model="search.name"
          type="search"
          class="form-control"
          placeholder="Name"
          @keyup.enter="handleSearchChange"
        />
      </div>
      <div class="col-3">
        <input
          v-model="search.email"
          type="search"
          class="form-control"
          placeholder="Email"
          @keyup.enter="handleSearchChange"
        />
      </div>
      <div class="col-3">
        <select
          v-model="search.org"
          class="form-select"
          @change="handleSearchChange"
        >
          <option value="">- all organizations -</option>
          <option v-for="org in orgs" :key="org.id" :value="org.id">
            {{ org.name }}
          </option>
        </select>
      </div>
      <div class="col-3">
        <select
          v-model="search.role"
          class="form-select"
          @change="handleSearchChange"
        >
          <option value="">- all roles -</option>
          <option v-for="role in allRoles" :key="role" :value="role">
            {{ role }}
          </option>
        </select>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th v-if="showOrgs">Organization</th>
          <th>Roles</th>
          <th>Last login</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
          role="button"
          @click="clickUser(user.id)"
        >
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone }}</td>
          <td v-if="showOrgs">{{ orgName(user.organization_id) }}</td>
          <td>{{ renderRoles(user) }}</td>
          <td>{{ formatDate(user.last_login) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="row">
      <PageSelect
        :total-entries="pagination.total"
        :limit-per-page="pagination.limit"
        :offset-value="pagination.offset"
        @new-offset-value="handleOffsetChange"
        @new-limit-per-page="newLimitPerPage"
      />
    </div>
  </div>

  <UserDetailsModal
    v-if="addUserVisible"
    :orgs="showOrgs ? orgs : false"
    :roles="roles"
    @close="hideAddUserModal"
    @change="loadUserData"
  />
</template>
<script setup>
import { onBeforeMount, ref, onMounted, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { formatDate } from "@/util/formatting";
import backend from "@/backend";
import PageSelect from "@/components/PageSelect.vue";
import usePolicy from "@/util/usePolicy";
import UserDetailsModal from "./components/UserDetailsModal.vue";

const store = useStore();
const router = useRouter();
const { allowed } = usePolicy();

const currentUser = store.getters.currentUser;

const users = ref([]);
const orgs = ref([]);
const roles = computed(() => backend.users.getAvailableRoles(currentUser));
const allRoles = ref([]);
const showOrgs = ref(false);
const addUserVisible = ref(false);
const pagination = reactive({
  total: 0,
  limit: null,
  offset: null,
});
const search = reactive({
  name: "",
  email: "",
  org: "",
  role: "",
});

function clickUser(userId) {
  if (userId == currentUser.id) router.push("/users/current");
  else router.push("/users/" + userId);
}

function renderRoles(user) {
  let result = "";
  if (user.roles) {
    return user.roles.join(", ");
  }
  for (const key in user) {
    if (key.startsWith("role_") && user[key]) {
      if (result) result += " ";
      result += key.substring("role_".length);
    }
  }
  return result;
}

function newLimitPerPage(limit) {
  pagination.limit = limit;
  store.commit("SET_USERS_LIST_LIMIT", parseInt(pagination.limit));
  pagination.offset = 0;
  loadUserData();
}

function handleOffsetChange(newOffsetVal) {
  newOffsetValue(newOffsetVal);
  loadUserData();
}

function newOffsetValue(newOffsetVal) {
  pagination.offset = newOffsetVal;
  store.commit("SET_USERS_LIST_OFFSET", parseInt(pagination.offset));
  loadUserData();
}

function showAddUserModal() {
  addUserVisible.value = true;
}

function hideAddUserModal() {
  addUserVisible.value = false;
}

function orgName(orgId) {
  for (const org of orgs.value) if (org.id == orgId) return org.name;
}

async function loadUserData() {
  const result = await backend.users.listUsers(
    pagination.limit,
    pagination.offset,
    search
  );
  pagination.total = result.total_count;
  users.value = result.users;
  store.commit("SET_USERS_LIST_LIMIT", parseInt(pagination.limit));
}

function handleSearchChange() {
  newOffsetValue(0); //always jump to first page after applying search terms
  loadUserData();
}

function getLimit() {
  const limit = store.state?.usersListLimit ?? 20;
  return parseInt(limit);
}

function getOffset() {
  const offset = store.state?.usersListOffset ?? 0;
  return parseInt(offset);
}

onBeforeMount(() => {
  pagination.limit = pagination.limit ?? getLimit();
  pagination.offset = pagination.offset ?? getOffset();
});

onMounted(async function () {
  allRoles.value = backend.users.getAllRoles();
  await loadUserData();
  if (currentUser.actions.includes("ACCESS_ALL_ORGANIZATIONS")) {
    orgs.value = await backend.organizations.listOrganizations();
    showOrgs.value = true;
  }
});
</script>
