<template>
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Crash Logs</h2>
    </div>
    <div>
      <div class="row mt-3">
        <div class="col-md-2">
          <label for="deviceSerialNumber" class="fw-bolder mb-2"
            >Device serial number</label
          >
          <input
            id="deviceSerialNumber"
            v-model="deviceSerialNumberFilter"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mt-3 justify-content-start py-2 mb-2">
        <div class="col-md-6 text-start">
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-right: 15px"
            @click.prevent="loadData"
          >
            Filter
          </button>
          <button class="btn btn-secondary btn-sm" @click="clearFilter">
            Reset
          </button>
        </div>
      </div>
    </div>

    <table class="table table-striped table-hover table-sm">
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Organization</th>
          <th>Clinic</th>
          <th>Serial</th>
          <th>Model</th>
          <th>Revision</th>
          <th>Log File</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="log in logs"
          :key="log.id"
          role="button"
          @click="router.push(`/logs/crash/${log.id}`)"
        >
          <td>{{ log.id }}</td>
          <td>{{ formatDate(log.created) }}</td>
          <td>{{ log.organization_name }}</td>
          <td>{{ log.location_name }}</td>
          <td>{{ log.device_serial }}</td>
          <td>{{ log.device_model }}</td>
          <td>{{ log.device_revision }}</td>
          <td>
            <p>
              <a :href="log.s3_path" :download="'log_' + log.id + '.zip'">
                Download Log
              </a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <PageSelect
      :total-entries="totalEntries"
      :limit-per-page="limitPerPage"
      :offset-value="offsetValue"
      @new-offset-value="newOffsetValue"
      @new-limit-per-page="newLimitPerPage"
    />
  </div>
</template>
<script setup>
import { listCrashLogs } from "@/backend/useLogsAPI";
import PageSelect from "@/components/PageSelect.vue";
import { formatDate } from "@/util/formatting";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const logs = ref([]);
const totalEntries = ref(0);
const limitPerPage = ref(20);
const offsetValue = ref(0);
const deviceSerialNumberFilter = ref("");
const router = useRouter();

async function loadData() {
  const logData = await listCrashLogs({
    limit: limitPerPage.value,
    offset: offsetValue.value,
    sort: "-id",
    serial: deviceSerialNumberFilter.value,
  });
  logs.value = logData.logs;
  totalEntries.value = logData.total_count;
}

function clearFilter() {
  deviceSerialNumberFilter.value = "";
  loadData();
}

function newLimitPerPage(limit) {
  limitPerPage.value = limit;
  loadData();
}

function newOffsetValue(newOffsetVal) {
  offsetValue.value = newOffsetVal;
  loadData();
}

onMounted(async function () {
  loadData();
});
</script>
