<template>
  <BreadCrumbs
    v-if="!fullScreenView"
    :current="'Visit ' + visit.id"
    parent="Visits"
    parent-path="/visits"
  />
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Visit {{ visit.id }}</h2>
      <div class="ms-auto">
        <template v-if="!fullScreenView">
          <button
            v-if="visit.created && !visit.scanned && allowed('START_VISITS')"
            class="btn btn-primary"
            @click="deleteClicked"
          >
            Delete
          </button>
          <button
            v-if="
              visit.scanned &&
              !visit.analysis_started &&
              allowed('ANALYZE_VISITS')
            "
            class="btn btn-oivi-light"
            @click="beginClicked"
          >
            Begin analysis
          </button>
          <button
            v-if="
              visit.analysis_started &&
              !visit.analysis_finished &&
              allowed('ANALYZE_VISITS')
            "
            class="btn btn-primary ms-3"
            @click="gotoAnalysis"
          >
            Continue analysis
          </button>
          <button
            v-if="
              visit.analysis_finished &&
              !visit.reviewed &&
              allowed('REVIEW_VISITS')
            "
            class="btn btn-oivi-light ms-3"
            @click="confirmReviewVisible = true"
          >
            Mark as reviewed
          </button>
        </template>
        <button
          v-if="fullScreenView"
          class="btn btn-secondary ms-3"
          @click="router.push('/visits/lookup')"
        >
          Back
        </button>
        <button
          v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
          class="btn btn-oivi-light ms-3"
          @click="viewReport"
        >
          View report
        </button>

        <template v-if="!fullScreenView">
          <button
            v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
            class="btn btn-oivi-light ms-3"
            @click="saveReport"
          >
            Download
          </button>
          <button
            v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
            class="btn btn-oivi-light ms-3"
            @click="resendReport"
          >
            (Re)Send report to patient
          </button>
          <i
            v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
            id="info-icon"
            class="bi bi-info-circle ms-2"
            data-toggle="tooltip"
            title="To send automatically, enable this at the clinic location page"
          ></i>
        </template>
      </div>
    </div>

    <div v-if="visit.analysis_finished" class="row mt-3">
      <div class="col-md-12">
        <h4 style="border-bottom: 1px solid gray">Analysis result</h4>
        <dl class="row">
          <dt class="col-lg-2 col-6">Diabetic Retinopathy (Right eye):</dt>
          <dd class="col-lg-4 col-6">
            <span
              :class="
                !visit.dr_grading_od
                  ? ''
                  : visit.flagged
                    ? 'text-danger'
                    : 'text-success'
              "
            >
              {{ DrGrades[visit.dr_grading_od] ?? "N/A" }}
            </span>
          </dd>
          <dt class="col-lg-2 col-6">Diabetic Retinopathy (Left eye):</dt>
          <dd class="col-lg-4 col-6">
            <span
              :class="
                !visit.dr_grading_os
                  ? ''
                  : visit.flagged
                    ? 'text-danger'
                    : 'text-success'
              "
            >
              {{ DrGrades[visit.dr_grading_os] ?? "N/A" }}
            </span>
          </dd>
          <dt class="mt-2 col-lg-2 col-6">Recommendation:</dt>
          <dd class="mt-2 col-lg-4 col-6">
            <span :class="visit.flagged ? 'text-danger' : ''">
              {{ AppConfig.getVisitRecommendation(visit) }}
            </span>
          </dd>
        </dl>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Visit details</h4>
        <dl class="row">
          <dt class="col-md-4 col-6">Status:</dt>
          <dd class="col-md-8 col-6">{{ formatStatus(visit) }}</dd>

          <template v-if="visit.location_id">
            <dt class="col-md-4 col-6">Clinic:</dt>
            <dd class="col-md-8 col-6">
              <router-link
                v-if="!fullScreenView"
                :to="'/locations/' + visit.location_id"
                >{{ visit.location_name }}</router-link
              >
              <span v-else>{{ visit.location_name }} </span>
            </dd>
          </template>

          <dt class="col-md-4 col-6">Started:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.created) }}
            <ByLine
              :user-i-d="visit.creator_id"
              :user-name="visit.creator_name"
              :disable-link="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Scanned:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.scanned) || "-" }}
            <ByLine
              :user-i-d="visit.operator_id"
              :user-name="visit.operator_name"
              :disable-link="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Analysis started:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.analysis_started) || "-" }}
            <ByLine
              v-if="!visit.analysis_finished"
              :user-i-d="visit.analyst_id"
              :user-name="visit.analyst_name"
              :disable-link="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Analysis finished:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.analysis_finished) || "-" }}
            <ByLine
              v-if="visit.analysis_finished"
              :user-i-d="visit.analyst_id"
              :user-name="visit.analyst_name"
              :disable-link="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Reviewed:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.reviewed) || "-" }}
            <ByLine
              v-if="visit.reviewed"
              :user-i-d="visit.reviewer_id"
              :user-name="visit.reviewer_name"
              :disable-link="fullScreenView"
            />
          </dd>
        </dl>
      </div>
      <div class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Patient details</h4>
        <dl class="row">
          <template v-if="visit.patient_id">
            <dt class="col-md-4 col-6">Name:</dt>
            <dd class="col-md-8 col-6">
              <router-link
                v-if="!fullScreenView"
                :to="'/patients/' + visit.patient_id"
              >
                {{ visit.name }}
              </router-link>
              <span v-else>
                {{ visit.name }}
              </span>
            </dd>

            <dt class="col-md-4 col-6">Contact information:</dt>
            <dd class="col-md-8 col-6">
              {{ visit.email }},
              {{ visit.phone }}
            </dd>
          </template>

          <dt class="col-md-4 col-6">Age, gender:</dt>
          <dd class="col-md-8 col-6">{{ visit.age }}, {{ visit.gender }}</dd>

          <dt class="col-md-4 col-6">Diabetes:</dt>
          <dd class="col-md-8 col-6">
            {{ formatYearsMonthsOnset(visit.diabetes_onset) }}
          </dd>

          <dt class="col-md-4 col-6">Hypertension:</dt>
          <dd class="col-md-8 col-6">
            {{ formatYearsMonthsOnset(visit.hypertension_onset) }}
          </dd>

          <dt class="col-md-4 col-6">Cataract (OD):</dt>
          <dd class="col-md-8 col-6">
            {{ formatCataract(visit.cataract_od) }}
          </dd>

          <dt class="col-md-4 col-6">Cataract (OS):</dt>
          <dd class="col-md-8 col-6">
            {{ formatCataract(visit.cataract_os) }}
          </dd>

          <dt class="col-md-4">Notes:</dt>
          <dd class="col-md-8 note">{{ visit.notes }}</dd>
        </dl>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Visit notes</h4>
        <VisitNotes :visit-i-d="visitID" />
      </div>
      <div v-if="visit.scanned" class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Images</h4>

        <div class="row">
          <div v-for="image in images" :key="image.id" class="col-md-3">
            <ThumbnailImage :image="image" @click="imageClicked" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalForm v-show="errorMessage" title="Error" @close="errorMessage = ''">
    <div class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div class="mt-5">
      <button class="btn btn-secondary" @click="errorMessage = ''">
        Close
      </button>
    </div>
  </ModalForm>
  <ModalForm
    v-show="confirmReviewVisible"
    title="Confirm review"
    @close="confirmReviewVisible = false"
  >
    <p>
      Please confirm that review of this visit is complete. This action can't be
      undone.
    </p>
    <div class="text-center mt-5">
      <button
        class="btn btn-secondary me-3"
        @click="confirmReviewVisible = false"
      >
        Cancel
      </button>
      <button class="btn btn-primary" @click="confirmReview">Confirm</button>
    </div>
  </ModalForm>
  <ModalForm
    v-show="showImageCanvas"
    :make-large="true"
    @close="closeImageCanvas"
  >
    <ImageCanvas
      ref="ic"
      :view_only="true"
      :enable-navigation="true"
      @next-image="nextImage"
      @prev-image="prevImage"
    />
  </ModalForm>

  <ModalForm
    v-show="showPdfModal"
    class="report-modal"
    :make-large="true"
    @close="closePdfModal"
  >
    <iframe
      :src="reportUrl"
      width="100%"
      height="100%"
      style="border: none"
    ></iframe>
  </ModalForm>
</template>
<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import backend from "@/backend";
import ByLine from "@/components/ByLine.vue";
import ModalForm from "@/components/ModalForm.vue";
import ThumbnailImage from "@/components/ThumbnailImage.vue";
import { DrGrades } from "@/pages/Annotation/AnnotationGuide";
import ImageCanvas from "@/pages/Annotation/components/ImageCanvas.vue";
import VisitNotes from "@/pages/Annotation/components/VisitNotes.vue";
import router from "@/router";
import type { Image, Visit } from "@/types";
import { AppConfig } from "@/util/AppConfig";
import {
  formatCataract,
  formatDate,
  formatStatus,
  formatYearsMonthsOnset,
} from "@/util/formatting";
import usePolicy from "@/util/usePolicy";
import { Tooltip } from "bootstrap";
import { onMounted, ref, Ref } from "vue";
import { useStore } from "vuex";

interface Props {
  visitID: string;
}

const props = defineProps<Props>();

const { allowed } = usePolicy();
const store = useStore();

const visit: Ref<Visit> = ref({});
const errorMessage: Ref<string> = ref("");
const images: Ref<Image[]> = ref([]);
const confirmReviewVisible: Ref<boolean> = ref(false);
const showImageCanvas: Ref<boolean> = ref(false);

const ic: Ref<any> = ref(null);
const currentImageIndex = ref<number>(-1);

const showPdfModal: Ref<boolean> = ref(false);
const reportUrl = `/visits/${props.visitID}/report`;

const fullScreenView: boolean =
  router.currentRoute.value.query["full-screen-view"] === "true";

async function loadVisit(): Promise<void> {
  try {
    const visitResult: Promise<Visit> = backend.visits.getVisit(props.visitID);
    const imagesResult: Promise<Image[]> = backend.visits.getVisitImages(
      props.visitID
    );

    visit.value = await visitResult;
    images.value = await imagesResult;
  } catch (error) {
    console.error("Error loading visit data:", error);
    errorMessage.value = "Failed to load visit data. Please try again.";
  }
}

function gotoAnalysis(): void {
  router.push("/visits/" + props.visitID + "/analysis");
}

async function beginClicked(): Promise<void> {
  try {
    await backend.visits.beginAnalysis(props.visitID);
    gotoAnalysis();
  } catch (error) {
    console.error("Error beginning analysis:", error);
    errorMessage.value = "Failed to begin analysis. Please try again.";
  }
}

async function deleteClicked(): Promise<void> {
  try {
    await backend.visits.deleteVisit(props.visitID);
    router.push("/visits");
  } catch (error: any) {
    if (error?.response?.status == 409) {
      errorMessage.value =
        "Visit is referenced by other data. Deletion not allowed.";
      return;
    }
    console.error("Error deleting visit:", error);
    errorMessage.value = "Failed to delete visit. Please try again.";
  }
}

async function confirmReview(): Promise<void> {
  try {
    await backend.visits.markReviewed(props.visitID);
    confirmReviewVisible.value = false;
    await loadVisit();
  } catch (error) {
    console.error("Error marking as reviewed:", error);
    errorMessage.value = "Failed to mark as reviewed. Please try again.";
  }
}

async function logAction(actionType: string): Promise<void> {
  const userId = store.state?.currentUser?.id;
  await backend.audit.logUserAction(userId, actionType, props.visitID);
}

async function resendReport(): Promise<void> {
  try {
    await backend.visits.resendReport(props.visitID);
  } catch (error) {
    console.error("Error resending report:", error);
    errorMessage.value = "Failed to resend report. Please try again.";
  }
}

async function saveReport(): Promise<void> {
  try {
    const result = await backend.visits.downloadReport(props.visitID);
    const actionType = backend.audit.userActionTypes.DOWNLOAD_REPORT;
    await logAction(actionType);

    const url = URL.createObjectURL(
      result.slice(0, result.size, "application/octet-stream")
    );
    store.commit("SET_LAST_VIEWED_REPORT", url);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Oivi_Report_" + props.visitID + ".pdf";
    a.click();
  } catch (error) {
    console.error("Error downloading report:", error);
    errorMessage.value = "Failed to download report. Please try again.";
  }
}

function imageClicked(id: string): void {
  for (let i = 0; i < images.value.length; i++) {
    if (images.value[i].id === id) {
      currentImageIndex.value = i;
      ic.value.loadImage(id);
      showImageCanvas.value = true;
      break;
    }
  }
}

async function viewReport(): Promise<void> {
  showPdfModal.value = true;
}

function closePdfModal(): void {
  showPdfModal.value = false;
}

new Tooltip(document.body, {
  selector: "[data-toggle='tooltip']",
});

function closeImageCanvas() {
  showImageCanvas.value = false;
  currentImageIndex.value = -1;
}

function nextImage() {
  if (!images.value.length || currentImageIndex.value < 0) return;
  currentImageIndex.value = (currentImageIndex.value + 1) % images.value.length;
  ic.value.loadImage(images.value[currentImageIndex.value].id);
}

function prevImage() {
  if (!images.value.length || currentImageIndex.value < 0) return;
  currentImageIndex.value =
    (currentImageIndex.value - 1 + images.value.length) % images.value.length;
  ic.value.loadImage(images.value[currentImageIndex.value].id);
}

onMounted(loadVisit);
</script>
<style scoped>
.cover-item {
  display: block;
  margin: 0 0 1rem 0;
  border-radius: 10px;
  background-clip: border-box;
  border: 1px solid rgb(248, 249, 250);
  width: 100%;
  height: 0;
  padding-bottom: 86%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pdf-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
}

.report-modal {
  max-width: 100% !important;
}
</style>
<style>
.report-modal {
  .modal-container {
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding-right: 32px;

    .title {
      display: none;
    }

    .modal-content-container {
      height: 96%;
      padding: 0px !important;
      margin: 0px !important;
      overflow: hidden !important;
      width: 96%;
    }
  }
}
</style>
